import React from "react";
import {
  Container,
  Row,
  Col,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Card,
  CardImg,
} from "reactstrap";

function Learning(props) {
  return (
    <div>
      <img
        src="/assets/images/training.jpg"
        alt="Learning-آموزش"
        width="100%"
      />
      <Container>
        <Row className="justify-content-center">
          <Col lg="9" md="9" sm="12" className="">
            <Card>
              <CardBody>
                <CardTitle>آموزش</CardTitle>

                <CardSubtitle>شرکت هوشمند فناوران سلامت گستر چابک</CardSubtitle>
                <div className="jingol"></div>

                <CardText>
                  این شرکت می تواند بصورت مجازی و حضوری دوره هایی در زمینه های
                  ذیل با استفاده از اساتید مجرب برگزار نماید.
                </CardText>
                <ul>
                  <li>دوره آموزشی ارزیابی حسی </li>
                  <li>دوره آموزشی اصول تحقیق و توسعه </li>
                  <li>دوره آموزشی مدیریت کیفیت در کارخانجات صنایع غذایی </li>
                  <li>
                    دوره آموزشی روش های نوین درون پوشانی شامل لیگوزوم، نیوزوم و
                    توکوزوم{" "}
                  </li>
                  <li>دوره آموزشی اصول کشت و فرآوری گیاهان دارویی</li>
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg="4" md="6" sm="12" className="mb-3">
            <Card>
              <CardImg src="/assets/images/آموزش1.jpg"></CardImg>
            </Card>
          </Col>
          <Col lg="4" md="6" sm="12" className="mb-3">
            <Card>
              <CardImg src="/assets/images/آموزش2.jpg"></CardImg>
            </Card>
          </Col>
          <Col lg="4" md="6" sm="12" className="mb-3">
            <Card>
              <CardImg src="/assets/images/آموزش3.jpg"></CardImg>
            </Card>
          </Col>
          <Col lg="4" md="6" sm="12" className="mb-3">
            <Card>
              <CardImg src="/assets/images/آموزش4.jpg"></CardImg>
            </Card>
          </Col>
          <Col lg="4" md="6" sm="12" className="mb-3">
            <Card>
              <CardImg src="/assets/images/آموزش 5.jpg"></CardImg>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Learning;
