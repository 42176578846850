import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props) {
    return (
        <div className="footer pt-5 pb-1">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 text-right">
                        <h5>تماس با ما</h5>
                        <address>

                            <br />
                            <i className="fa fa-phone fa-lg"></i>   موبایل : 09151171865 <br />
                            <i className="fa fa-fax fa-lg"></i>   تلفن : 05135421115 <br />
                            <i className="fa fa-instagram fa-lg"></i>   اینستاگرام : <a href='https://instagram.com/ahealthyst?igshid=1a8ew1az1bm4t'> ahealthyst@ </a> <br />
                            <i className="fa fa-envelope fa-lg"></i>: <a className="text-info" href="mailto:info@ahealthyst.com">
                info@ahealthyst.com</a> 
                            <ul className="list-unstyled pr-0">
                                <li>آدرس دفتر : ساختمان مرکزی پارک علم و فناوری خراسان رضوی واحد 324 </li>
                            </ul>
                        </address>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <h5 className="text-right">منو سریع</h5>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">

                                <ul className="list-unstyled text-right  pull-right">
                                    <li><Link to='/home'>صفحه اصلی</Link></li>
                                    <li><Link to='/aboutus'>درباره ما</Link></li>
                                    {/* <li><Link to='/whoWeAre'>  اعضای شرکت</Link></li> */}
                                    <li><Link to='/foodIndustry'>  فرمولاسیون صنایع غذایی</Link></li>
                                    <li><Link to='/cosmetics'>  محصولات سلامت محور  </Link></li>

                                </ul>

                            </div>
                            <div className="col-lg-6 col-md-6">
                                <ul className="list-unstyled text-right pull-right">
                                    <li><Link to='/medicinalPlants'>فرآوری گیاهان دارویی</Link></li>
                                    <li><Link to='/learning'> آموزش</Link></li>
                                    <li><Link to='/products'>محصولات</Link></li>
                                    <li><Link to='/researches'>پژوهش ها</Link></li>
                                    <li><Link to='/contract'>قراردادهای تحقیقاتی</Link></li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    {/* <div className="col-12 col-sm-4 align-self-center">
                        <div className="text-center">
                            <a className="btn btn-social-icon btn-google" href="http://google.com/+"><i className="fa fa-google-plus"></i></a>
                            <a className="btn btn-social-icon btn-facebook" href="http://www.facebook.com/profile.php?id="><i className="fa fa-facebook"></i></a>
                            <a className="btn btn-social-icon btn-linkedin" href="http://www.linkedin.com/in/"><i className="fa fa-linkedin"></i></a>
                            <a className="btn btn-social-icon btn-twitter" href="http://twitter.com/"><i className="fa fa-twitter"></i></a>
                            <a className="btn btn-social-icon btn-google" href="http://youtube.com/"><i className="fa fa-youtube"></i></a>
                            <a className="btn btn-social-icon" href="mailto:"><i className="fa fa-envelope-o"></i></a>
                        </div>
                    </div> */}
                </div>
               <div className="row justify-content-center dir-ltr ">
                    <div className="col-auto col-12">
                        <p className=" Cp">© Copyright 2020 Agile Smart Health Technology|-Product by <a href="https://www.linkedin.com/in/elham-baradaran-heravi/" target="blank">Elham B-Heravi</a></p>
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default Footer;