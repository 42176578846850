import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardSubtitle,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";

function AboutUs() {
  return (
    <div>
      <img
        src="/assets/images/aboutus.jpg"
        width="100%"
        alt="aboutus-درباره ما"
      />
      <Container>
        <Row className="justify-content-center">
          <Col lg="9" md="9" sm="12" className="">
            <Card>
              <CardBody>
                <CardTitle>درباره ما</CardTitle>

                <CardSubtitle>
                  {" "}
                  شرکت هوشمند فناوران سلامت گستر چابک (شماره ثبت 71888){" "}
                </CardSubtitle>

                <div className="jingol"></div>

                <CardText>
                  شرکت هوشمند فناوران سلامت گستر چابک با رویکرد استفاده از
                  تکنولوژی های هوشمند در حوزه های فرمولاسیون مکمل ها و فرآورده
                  های گیاهی، صنایع غذایی و محصولات سلامت محور و انجام ارزیابی
                  حسی جهت ارائه در بازارهای داخلی و خارجی تاسیس گردید. ما در این
                  راه همواره دست یابی به استقلال و تحکیم تفکر خودباوری و عرضه
                  محصولات برتر را در نظر داشته ایم. این مجموعه به عنوان یک شرکت
                  خصوصی تلاش دارد تا با استقرار تکنولوژی های هوشمند و مدیریت
                  بهره گیری از فرمولاسیون های نوین، راهبردهای توسعه ای خود را
                  برنامه ریزی نموده و گام های بلندی را در بهبود و توسعه حوزه
                  سلامت مردم کشور عزیزمان ایران، را رقم بزند.
                  <br />
                  در حقیقت همان طور که از اسم این شرکت بر می آید، ما در پی
                  هوشمند سازی تکنولوژی یا به تعبیری فرآیندی چابک هستیم که در آن
                  استفاده از مواد و فرآیند های غیر ضروری و ناسالم در فرمولاسیون
                  های غذایی، آرایشی و بهداشتی و مکمل ها به حداقل برسد و جهت گیری
                  توسعه فناوری و تغییرات نهادی، با نیازهای افراد در حال و آینده
                  سازگار باشد تا سلامتی نسل‌های آینده را برای رفع نیازهای خود به
                  خطر نیاندازد.
                </CardText>
              </CardBody>
            </Card>
  
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AboutUs;
