import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";

function SensoryEvaluation(props) {
  return (
    <div>
      <img
        src="/assets/images/sensor.jpeg"
        alt="sensor evaluation"
        width="100%"
      />
      <Container>
        <Row>
          <Col md={6} className="text-right pr-5">
            <Card>
              <CardBody>
                <CardTitle>ارزیابی حسی </CardTitle>
                <div className="jingol"></div>

                <CardText>
                  شرکت هوشمند فناوران سلامت گستر چابک در زمینه ارزیابی حسی
                  فرمولاسیون های جدید و مقایسه با محصولات رقبا در دو مرحله تخصصی
                  و تحقیقات بازار در محیطی کاملا ایزوله مطابق با آخرین
                  استانداردهای روز دنیا فعالیت دارد . که از این طریق می توان
                  فرمول محصولات را قبل از تولید انبوه از لحاظ ویژگی های حسی (
                  عطر، طعم ، بافت و ....) و بازخورد نظر مشتریان با استفاده از
                  تکنیک ها و روش های آماری بررسی نمود.
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <div className="d-flex justify-content-center py-5">
              <img
                src="assets/images/sensor2.jpeg"
                width="90%"
                className=""
                alt="sensor evaluation"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default SensoryEvaluation;
