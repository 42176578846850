import React from 'react';
import {
  Container,Row, Col,Card, CardBody,CardSubtitle,
  CardTitle,
  CardText
} from 'reactstrap';

function ContactUs(props) {
  return (
    <div >
    <img src='/assets/images/contactUs.jpg' alt="contactUs" width='100%'/>
    <Container>
        <Row className='justify-content-center'>
            <Col lg='9' md='9' sm='12' className=''>
              <Card>
                <CardBody>
                  <CardTitle>تماس با ما</CardTitle>
                  
                                  <CardSubtitle>شرکت هوشمند فناوران سلامت گستر چابک</CardSubtitle>
                  <div className='jingol'></div>
                  
               <CardText>
               لطفا قبل از تماس، ابتدا سوال و موضوع مورد نظر را به ایمیل شرکت ارسال فرمائید.
              <address>
              <ul>
                     <li>	آدرس دفتر : ساختمان مرکزی پارک علم و فناوری خراسان رضوی واحد 324 </li>
                  </ul>
                <br />
                <i className="fa fa-phone fa-lg"></i> موبایل : 09151171865 <br />
                <i className="fa fa-fax fa-lg"></i> تلفن : 05135421115 <br />
                <i className="fa fa-instagram fa-lg"></i>   اینستاگرام : <a  className="text-info" href='https://instagram.com/ahealthyst?igshid=1a8ew1az1bm4t'> ahealthyst@ </a> <br />
                <i className="fa fa-envelope fa-lg"></i>: <a className="text-info" href="mailto:info@ahealthyst.com">
                info@ahealthyst.com</a> 
               
              </address>
              </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>       
    </Container>
</div>

  );
}

export default ContactUs;   