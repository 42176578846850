import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
import PdfFile from "../assets/report.pdf";

function Cosmetics(props) {
  return (
    <div>
      <img
        src="/assets/images/cosmetic.jpg"
        width="100%"
        alt="cosmetic-محصولات سلامت محور"
      />
      <Container>
        <Row className="justify-content-center">
          <Col lg="9" md="9" sm="12">
            <CardBody>
              <CardTitle>محصولات سلامت محور </CardTitle>
              <CardSubtitle> شرکت هوشمند فناوران سلامت گستر چابک </CardSubtitle>
              <div className="jingol"></div>
              <CardText>
                کالاهای سلامت محور فرآورده هایی هستند که با سلامت مردم سرو کار
                دارند و شامل انواع محصولات آرایشی، بهداشتی، خوراکی، آشامیدنی،
                دارو، مکمل و فرآورده های طبيعي و گیاهی مي باشند.
              </CardText>
              <CardText>
                شرکت هوشمند فناوران سلامت گستر چابک با بهره گیری از روش های نوین
                در زمینه فرمولاسیون و تولید محصولات سلامت محور گیاهی وطبیعی قدم
                برمی دارد و در این حوزه از دانش متخصصان داخلی و خارجی استفاده می
                کند.
              </CardText>
            </CardBody>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="text-right pr-5">
            <Card>
              <CardBody>
                <CardSubtitle>
                  اقدامات انجام شده در این حوزه شامل :
                </CardSubtitle>
                <ul>
                  <li>استخراج کروسین (تولید کروسین) زعفران با خلوص 99/93<br/>
                    <a href={PdfFile} className="btn btn-link">آنالیز درصد خلوص کروسین و عصاره زعفران</a>
                     </li>
                  <li>استخراج آنتوسیانین از گلبرگ زعفران</li>
                  <li>تولید عصاره زعفران بصورت پودری و مایع </li>
                </ul>
                <CardText>لینک های خبر :</CardText>
                <ul className="news_link">
                  <li>
                    <a
                      className="btn btn-link"
                      href="http://www.qudsonline.ir/news/725119/%DA%A9%D8%B1%D9%88%D8%B3%DB%8C%D9%86-%D8%AF%D8%A7%D8%B1%D9%88%DB%8C%DB%8C-%D8%A7%D8%B2-%D8%B2%D8%B9%D9%81%D8%B1%D8%A7%D9%86-%D8%AF%D8%B1-%D9%85%D8%B4%D9%87%D8%AF-%D8%AA%D9%88%D9%84%DB%8C%D8%AF-%D8%B4%D8%AF"
                    >
                      قدس آنلاین :کروسین دارویی از زعفران در مشهد تولید شد
                    </a>
                  </li>
                  <li>
                    <a
                      className="btn btn-link"
                      href="https://www.iribnews.ir/fa/news/2878928/%DA%A9%D8%B1%D9%88%D8%B3%DB%8C%D9%86-%D8%AF%D8%A7%D8%B1%D9%88%DB%8C%DB%8C-%DA%AF%DB%8C%D8%A7%D9%87%DB%8C-%D8%A7%D8%B2-%D8%AF%D9%84-%D8%B2%D8%B9%D9%81%D8%B1%D8%A7%D9%86"
                    >
                      خبرگزاری صدا و سیما :کروسین دارویی گیاهی، از دل زعفران
                    </a>
                  </li>
                  <li>
                    <a
                      className="btn btn-link"
                      href="https://www.ilna.news/%D8%A8%D8%AE%D8%B4-%D8%A7%D8%B3%D8%AA%D8%A7%D9%86-%D9%87%D8%A7-15/986144-%D8%AA%D9%88%D9%84%DB%8C%D8%AF-%DA%A9%D8%B1%D9%88%D8%B3%DB%8C%D9%86-%D8%AF%D8%A7%D8%B1%D9%88%DB%8C%DB%8C-%D8%A7%D8%B2-%D8%B2%D8%B9%D9%81%D8%B1%D8%A7%D9%86-%D8%A8%D8%A7-%D8%AE%D9%84%D9%88%D8%B5-%D8%A8%D8%A7%D9%84%D8%A7%DB%8C-%D8%AF%D8%B1%D8%B5%D8%AF-%D8%AF%D8%B1-%D9%85%D8%B4%D9%87%D8%AF"
                    >
                      ایلنا :تولید کروسین دارویی از زعفران با خلوص بالای ۹۸درصد
                      در مشهد
                    </a>
                  </li>
                  <li>
                    <a
                      className="btn btn-link"
                      href="https://ejiga.com/w/KG6v6Sa"
                    >
                      خبرگزاری باشگاه خبرنگاران: کروسین دارویی از زعفران در مشهد
                      تولید شد
                    </a>
                  </li>
                  <li>
                    <a
                      className="btn btn-link"
                      href="https://www.irna.ir/news/84086813/%DA%A9%D8%B1%D9%88%D8%B3%DB%8C%D9%86-%D8%AF%D8%A7%D8%B1%D9%88%DB%8C%DB%8C-%D8%A7%D8%B2-%D8%B2%D8%B9%D9%81%D8%B1%D8%A7%D9%86-%D8%AF%D8%B1-%D9%85%D8%B4%D9%87%D8%AF-%D8%AA%D9%88%D9%84%DB%8C%D8%AF-%D8%B4%D8%AF"
                    >
                      خبرگزاری جمهوری اسلامی : کروسین دارویی از زعفران در مشهد
                      تولید شد
                    </a>
                  </li>
                  <li>
                    <a
                      className="btn btn-link"
                      href="http://okt.ir/news/?page=info-khabar&code=160355292227227"
                    >
                      کروسین دارویی از زعفران در مشهد تولید شد
                    </a>
                  </li>
                  <li>
                    <a
                      className="btn btn-link"
                      href="http://econews.ir/fa/content/2768392"
                    >
                      خبرگزاری اقتصادی ایران : تولید کروسین دارویی گیاهی از
                      زعفران در مشهد
                    </a>
                  </li>
                  <li>
                    <a
                      className="btn btn-link"
                      href="https://www.ghatreh.com/news/nn55598045/%DA%A9%D8%B1%D9%88%D8%B3%DB%8C%D9%86-%D8%AF%D8%A7%D8%B1%D9%88%DB%8C%DB%8C-%D8%B2%D8%B9%D9%81%D8%B1%D8%A7%D9%86-%D9%85%D8%B4%D9%87%D8%AF-%D8%AA%D9%88%D9%84%DB%8C%D8%AF"
                    >
                      قطره:کروسین دارویی از زعفران در مشهد تولید شد
                    </a>
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <div className="d-flex justify-content-center py-5 ">
              <img
                src="assets/images/cosmetics.jpg"
                width="90%"
                alt="صنایع غذایی"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Cosmetics;
