import React, { Component } from "react";
import {
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
} from "reactstrap";
import { NavLink, Link } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggleNav = this.toggleNav.bind(this);
    this.state = {
      isNavOpen: false,
    };
  }

  toggleNav() {
    this.setState({
      isNavOpen: !this.state.isNavOpen,
    });
  }
  uptoTop() {
    this.toggleNav(); 
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  render() {
    return (
      <div>
        <Navbar
          expand="lg"
          color="dark"
          dark
          className={this.props.isTop ? "header" : "headerDark"}
          id="header"
        >
          <NavbarBrand className="pull-left" href="/">
            <img src="assets/images/logo.png" alt="Ristorante Con Fusion" />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNav} />
          <Collapse isOpen={this.state.isNavOpen} navbar>
            <Nav navbar>
              <NavItem>
                <NavLink
                  className="nav-link"
                  to="/home"
                  onClick={() => this.uptoTop()}
                >
                  صفحه اصلی
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                   className="nav-link"
                  to="/aboutus"
                  onClick={() => this.uptoTop()}
                >
                  درباره ما
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  className="nav-link"
                  to="/whoWeAre"
                  onClick={() => this.uptoTop()}
                >
                  {" "}
                  اعضای شرکت
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  className="nav-link"
                  to="/foodIndustry"
                  onClick={() => this.uptoTop()}
                >
                  فرمولاسیون صنایع غذایی
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className="nav-link"
                  to="/medicinalPlants"
                  onClick={() => this.uptoTop()}
                >
                  فرآوری گیاهان دارویی
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link"
                  to="/cosmetics"
                  onClick={() => this.uptoTop()}
                >
                  محصولات سلامت محور
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link"
                  to="/sensoryEvaluation"
                  onClick={() => this.uptoTop()}
                >
                  ارزیابی حسی
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link"
                  to="/learning"
                  onClick={() => this.uptoTop()}
                >
                  آموزش
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  دستاوردها
                </DropdownToggle>
                <DropdownMenu right className="text-right">
                  <DropdownItem>
                    <Link to="/products" onClick={() => this.uptoTop()}>
                      محصولات
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/researches" onClick={() => this.uptoTop()}>
                      پژوهش ها
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink
                  className="nav-link"
                  to="/contract"
                  onClick={() => this.uptoTop()}
                >
                  پروژه های تحقیقاتی
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link"
                  to="/contactus"
                  onClick={() => this.uptoTop()}
                >
                  تماس با ما
                </NavLink>
              </NavItem>
            </Nav>
            <a className="mt-3 mr-3" href="http://ahealthyst.com/">
              En
            </a>
          </Collapse>
         
        </Navbar>
      </div>
    );
  }
}

export default Header;
