import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardText,
  CardTitle,
  CardSubtitle,
} from "reactstrap";

function MedicinalPlants(props) {
  return (
    <div>
      <img
        src="/assets/images/medicinialPlants.jpeg"
        alt="medicinialPlants-گیاهان دارویی و مکمل ها"
        width="100%"
      />
      <Container>
        <Row className="justify-content-center">
          <Col lg="9" md="9" sm="12">
            <CardBody>
              <CardTitle>فرآوری گیاهان دارویی</CardTitle>
              <CardSubtitle> شرکت هوشمند فناوران سلامت گستر چابک </CardSubtitle>

              <div className="jingol"></div>
              <CardText>
                شرکت هوشمند فناوران سلامت گستر چابک در زمینه تامین انواع گیاهان
                دارویی مورد تائید اتحادیه تعاونی های تولید کننده گیاهان دارویی
                شامل زعفران، گل محمدی، نعنا فلفلی، آویشن ، بابونه و ... ، فرآوری
                ، استخراج و شناسایی مواد موثره و تغلیظ عصاره های گیاهی و استفاده
                در فرمولاسیون محصولات غذایی و مکمل ها با استفاده از روش های نوین
                فعالیت دارد.
              </CardText>
            </CardBody>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="text-right pr-5">
            <Card>
              <CardBody>
                <CardSubtitle>
                خدمات این شرکت در حوزه گیاهان دارویی و مکمل های گیاهی شامل :
                </CardSubtitle>
                <ul>
                  <li>تامین انواع گیاهان دارویی استاندارد </li>
                  <li>
                  	استخراج و عصاره گیری از گیاهان دارویی و فرآورده های گیاهی
                  </li>
                  <li>مشاوره فرمولاسیون های جدید با استفاده از عصاره گیاهان دارویی</li>
                  <li>ارائه فرمولاسیون های انواع نوشیدنی های سرد( سردنوش) و گرم ( دمنوش)</li>
                  <li>
                    مشاوره فرمولاسیون های مکمل های دارویی و استفاده از مواد
                    موثره گیاهی بر پایه نانو حامل ها (شامل لیپوزوم، نیوزوم و
                    توکوزوم) و استفاده از تکنیک آهسته رهش و رسانش هدفمند مواد
                    موثره به محل مورد نظر در بدن
                  </li>
                </ul>
                {/*<div className='d-flex justify-content-center p-2'>
                            <img src='assets/images/medicinalP1.jpg' alt="گیاهان دارویی و مکمل ها" />
                        </div> */}
              </CardBody>
              <CardImg
                bottom
                src="assets/images/medicinalP1.jpg"
                alt="گیاهان دارویی و مکمل ها"
              />
            </Card>
          </Col>
          <Col md={6}>
            <div className="d-flex justify-content-center py-5">
              <img
                src="assets/images/medicinalP2.jpg"
                width="90%"
                className=""
                alt="گیاهان دارویی و مکمل ها"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MedicinalPlants;
