import React from "react";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardSubtitle,
  Row,
  Col,
} from "reactstrap";
import { Stagger } from "react-animation-components";
import { Link } from "react-router-dom";
import windowDimensions from "react-window-dimensions";
import BackgroundSlider from "react-background-slider";
import Slide1 from "../assets/Slide1.jpg";
import Slide2 from "../assets/Slide2.jpg";
import Slide3 from "../assets/Slide3.jpg";

function Home({ width, height }) {
  return (
    <div>
      <BackgroundSlider
        images={[Slide1, Slide2, Slide3]}
        duration={10}
        transition={2}
      />

      <div style={{ height: `${height}px` }} className="compName py-auto">
        <Row className="align-items-center">
          <Col>
            <div style={{ paddingTop: `${height / 3 - 89}px` }}>
              <Stagger in>
                <h2>هوشمند فناوران سلامت گستر چابک</h2>
                <h3>Agile Smart Health Technology </h3>
                <h3>ارائه دهنده بهترین فرمولاسیون ها در حوزه صنایع غذایی و فرآوری گیاهان دارویی</h3>
              </Stagger>
              {/* <Fade in>
                <h3>
                  <a className="compName" href="http://anni.com.au/" target="blank">
                    تنها نماینده رسمی شرکت ANNI (مخترع تکنیک Mozafari Method )
                  </a>
                </h3>
              </Fade> */}
            </div>
          </Col>
        </Row>
      </div>

      <Row>
        <Col lg={3} md={3} sm={12} className="b1">
          <Link to="/foodIndustry" className="ab">
            فرمولاسیون در حوزه صنایع غذایی
          </Link>
        </Col>

        <Col lg={3} md={3} sm={12} className="b2">
          <Link to="/medicinalPlants" className="ab">
            فرآوری گیاهان دارویی
          </Link>
        </Col>
        <Col lg={3} md={3} sm={12} className="b3">
          <Link to="/cosmetics" className="ab">
            فرمولاسیون محصولات سلامت محور
          </Link>
        </Col>
        <Col lg={3} md={3} sm={12} className="b4">
          <Link to="/sensoryEvaluation" className="ab">
            ارزیابی حسی تخصصی محصولات
          </Link>
        </Col>
      </Row>
      <Container>
        <Row className="justify-content-center">
          <Col lg="9" md="9" sm="12" className="">
            <Card>
              <CardBody>
                <CardTitle>درباره ما</CardTitle>
                <CardSubtitle>
                  شرکت هوشمند فناوران سلامت گستر چابک (شماره ثبت 71888)
                </CardSubtitle>
                <div className="jingol"></div>
                <CardText>
                  شرکت هوشمند فناوران سلامت گستر چابک با رویکرد استفاده از
                  تکنولوژی های هوشمند در حوزه های فرمولاسیون مکمل ها و فرآورده
                  های گیاهی، صنایع غذایی و محصولات سلامت محور و انجام ارزیابی
                  حسی جهت ارائه در بازارهای داخلی و خارجی تاسیس گردید. ما در این
                  راه همواره دست یابی به استقلال و تحکیم تفکر خودباوری و عرضه
                  محصولات برتر را در نظر داشته ایم. این مجموعه به عنوان یک شرکت
                  خصوصی تلاش دارد تا با استقرار تکنولوژی های هوشمند و مدیریت
                  بهره گیری از فرمولاسیون های نوین، راهبردهای توسعه ای خود را
                  برنامه ریزی نموده و گام های بلندی را در بهبود و توسعه حوزه
                  سلامت مردم کشور عزیزمان ایران، را رقم بزند.
                  <br />
                  در حقیقت همان طور که از اسم این شرکت بر می آید، ما در پی
                  هوشمند سازی تکنولوژی یا به تعبیری فرآیندی چابک هستیم که در آن
                  استفاده از مواد و فرآیند های غیر ضروری و ناسالم در فرمولاسیون
                  های غذایی، آرایشی و بهداشتی و مکمل ها به حداقل برسد و جهت گیری
                  توسعه فناوری و تغییرات نهادی، با نیازهای افراد در حال و آینده
                  سازگار باشد تا سلامتی نسل‌های آینده را برای رفع نیازهای خود به
                  خطر نیاندازد.
                </CardText>
                <img src='/assets/images/agancy.png' alt="contactUs" width='100%'/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <div className="bgfix1">
        <Row className="justify-content-center">
          <Col md={8} lg={8}>
            <div className="colorW">
              <CardBody>
                <CardTitle>ارزیابی حسی</CardTitle>
                <CardText>
                  ارزیابی حسی فرمولاسیون های جدید و مقایسه با محصولات رقبا در دو
                  مرحله تخصصی و تحقیقات بازار در محیطی کاملا ایزوله مطابق با
                  آخرین استانداردهای روز دنیا انجام می شود. که از این طریق می
                  توان فرمول محصولات را قبل از تولید انبوه از لحاظ ویژگی های حسی
                  ( عطر، طعم ، بافت و ....) و بازخورد نظر مشتریان با استفاده از
                  تکنیک ها و روش های آماری بررسی نمود. تکنیک ها و روش های آماری
                  بررسی نمود.
                </CardText>
              </CardBody>
            </div>
          </Col>
        </Row>
      </div>
      <div className="bgdark colorW">
        <CardBody>
          <Row className="justify-content-center">
            <Col md={3} lg={3}>
              <CardTitle>ارائه فرمول های نوین و سلامت محور</CardTitle>
            </Col>
            <Col md={6} lg={6}>
              <CardText>
                این شرکت در راستای اهداف خود ،ارائه فرمولاسیون محصولات جدید و
                بهینه سازی فرمول محصولات فعلی شرکت های صنایع غذایی و گیاهی (
                انواع دمنوش و عصاره) را با استفاده از روش های نوین و هوشمند در
                دستور کار خود قرارداده است.
              </CardText>
            </Col>
          </Row>
        </CardBody>
      </div>
      <div className="bgfix2"></div>
    </div>
  );
}

export default windowDimensions()(Home);
