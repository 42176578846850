import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import Home from './home';
import AboutUs from './aboutus';
import FoodIndustry from './foodIndustry';
// import WhoWeAre from './whoWeAre';
import Cosmetics from './cosmetics';
import MedicinalPlants from './medicinalPlants';
import SensoryEvaluation from './sensoryEvaluation';
import Learning from './learning';
import Products from './products';
import Researches from "./researches";
import ContactUs from "./contactus";
import Contract from "./contract";
import { TransitionGroup, CSSTransition } from 'react-transition-group';

class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isTop: true,
          };
         }
    componentDidMount() {
        document.addEventListener('scroll', () => {
          const isTop = window.scrollY < 100;
          if (isTop !== this.state.isTop) {
              this.setState({ isTop })
          }
        });
      }

    render() {
        const HomePage = () => {
            return (
                <Home/>
            );
        }

        return (
            <Container fluid={true}>
                <Header  isTop={this.state.isTop}/>
                <TransitionGroup>
                    <CSSTransition key={this.props.location.key} classNames="page" timeout={300}>
                        <Switch location={this.props.location}>
                            <Route path='/home' component={HomePage} />
                            <Route exact path='/aboutus' component={() => <AboutUs />} />
                            <Route exact path='/foodIndustry' component={() => <FoodIndustry />} />
                            {/* <Route exact path='/whoWeAre' component={() => <WhoWeAre />} /> */}
                            <Route exact path='/cosmetics' component={() => <Cosmetics />} />
                            <Route exact path='/medicinalPlants' component={() => <MedicinalPlants />} />
                            <Route exact path='/sensoryEvaluation' component={() => <SensoryEvaluation />} />
                            <Route exact path='/learning' component={() => <Learning />} />
                            <Route exact path='/products' component={() => <Products />} />
                            <Route exact path='/researches' component={() => <Researches />} />
                            <Route exact path='/contract' component={() => <Contract />} />
                            <Route exact path='/contactUs' component={() => <ContactUs />} />
                            <Redirect to="/home" />
                        </Switch>
                        </CSSTransition>
          </TransitionGroup>
                <Footer />
            </Container>
        );
    }
}

export default withRouter(Main);