import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  CardText,
} from "reactstrap";

function FoodIndustry(props) {
  return (
    <div>
      <img
        src="/assets/images/foodIndustry.png"
        width="100%"
        alt="foodIndustry-صنایع غذایی"
      />
      <Container>
        <Row className="justify-content-center">
          <Col lg="9" md="9" sm="12">
            <CardBody>
              <CardTitle>فرمولاسیون صنایع غذایی</CardTitle>
              <CardSubtitle> شرکت هوشمند فناوران سلامت گستر چابک </CardSubtitle>

              <div className="jingol"></div>
              <CardText>
                شركت هوشمند فناوران سلامت گستر چابک با كسب تجربیات علمی و عملی و
                با بهره گیری از متخصصان حوزه صنعت غذا در زمینه غنی سازی و بهبود
                فرمولاسیون های محصولات خوراکی و همچنین تولید محصولات غذایی سلامت
                محور و استفاده از دانش و تکنولوژی روز دنیا در رسانش مواد موثره و
                کسب تائیدیه های و مجوزهای بهداشتی از جمله USA Patent و پروانه
                بهداشتی ایران، مالزی و استرالیا تا به اکنون گام های موثری را
                برداشته است. این شركت با دانش منحصر به فرد خود در زمینه شناسایی
                طعم و ارزیابی فرمولاسیون در سطح بین المللی از پیشگامان می باشد و
                این تخصص انحصاری برای تمامی تولید كنندگان موفق صنایع غذایی
                شناخته شده است.
              </CardText>
              <CardText>
                استفاده از روش های جدید مانند لیپوزوم ، نیوزوم و توکوزوم در
                انکپسولاسیون مواد موثره استفاده می شود در واقع ليپوزومها گويچه
                هاى ميكروسكوپى هستند كه براى ريزپوشانى و انتقال مواد زيست فعال
                به كار برده ميشوند. آنها پايدارى و تاريخ مصرف محصولات را افزايش
                ميدهند و جذب مواد مفيد را در بدن بالا برده، ميزان دفع را به
                حداقل ميرسانند. ليپوزومها جهاز هاضمه را از آسيبهاى مواد اسيدى
                منجمله برخى ويتامينها محافظت مينمايند.
              </CardText>
            </CardBody>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="text-right pr-5">
            <Card>
              <CardBody>
                <CardSubtitle>
                  خدمات این شرکت در حوزه صنایع غذایی شامل :
                </CardSubtitle>

                <ul>
                  <li>
                    مشاوره فرمولاسیون های تخصصی محصولات جدید و بهینه سازی فرمول
                    محصولات فعلی در تمامی شاخه های صنایع غذایی با استفاده از
                    تکنولوژی های جدید
                  </li>
                  <li>
                    بهبود فرآیندهای صنایع غذایی شامل مشاوره در حوزه طراحی و ساخت
                    ماشین آلات ، افزایش بهره وری و رفع مشکلات فنی در خط تولید
                  </li>
                  <li>
                    مشاوره فرمولاسیون های غنی شده بر پایه نانوحامل ها شامل
                    (لیپوزوم، نیوزوم و توکوزوم) در تمامی شاخه های صنایع غذایی
                    برای رسانش هدفمند مواد موثره به بدن انسان
                  </li>
                  <li>
                    انجام آزمون های حسی تخصصی و مشتری گرا محصولات غذایی جهت عرضه
                    به بازار
                  </li>
                  <li>
                    راهکارهای جهت افزایش زمان ماندگاری محصولات غذایی ازجمله
                    بهبود بسته بندی، استفاده از نگهدارنده های طبیعی و ....
                  </li>
                  <li>
                    اخذ استانداردهای و مجوزهای لازم در حوزه صنایع غذایی
                  </li>
                </ul>
                <div className="d-flex justify-content-center p-2">
                  <img src="assets/images/fi1.jpg" alt="صنایع غذایی" />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <div className="d-flex justify-content-center py-5">
              <img
                src="assets/images/fi2.jpg"
                width="90%"
                className=""
                alt="صنایع غذایی"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FoodIndustry;
