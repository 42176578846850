import React from 'react';
import { Container, Row, Col, Card, CardSubtitle, CardImg, CardBody, CardTitle } from 'reactstrap';

function Products(props) {
  return (
    <div >
      <img src='/assets/images/product.jpg' alt="محصولات" width='100%' />
      <Container>
        <Row>
          <Col md={12} className="text-right pr-5">
            <Card>

              <CardBody>
                <CardTitle>محصولات </CardTitle>
                <CardSubtitle>شرکت هوشمند فناوران سلامت گستر چابک</CardSubtitle>

                <div className='jingol'></div>
                <Row>

                  <Col lg='6' md='6' sm='12' className='mb-3'>
                    <Card>
                      <CardBody>
                        <CardImg className='ImgRadius' top width="100%" src="/assets/images/prod2/1.jpg" alt=" کرم بهبود دهنده بیماری پسوریازیس لیپوزومی-LIPOSOME PSORIASIS CREAM" />
                        <CardSubtitle className="black">( کروسین دارویی)</CardSubtitle>
                      </CardBody>
                    </Card>

                  </Col>
                  <Col lg='6' md='6' sm='12' className='mb-3'>
                    <Card>
                      <CardBody>
                        <CardImg className='ImgRadius' top width="100%" src="/assets/images/prod2/2.jpg" alt="کروسین غذایی" />
                        <CardSubtitle className="black">( کروسین غذایی)</CardSubtitle>
                      </CardBody>
                    </Card>

                  </Col>
                  <Col lg='6' md='6' sm='12' className='mb-3'>
                    <Card>
                      <CardBody>
                        <CardImg className='ImgRadius' top width="100%" src="/assets/images/prod2/3.jpg" alt="کروسین آرایشی " />
                        <CardSubtitle className="black">( کروسین آرایشی )</CardSubtitle>
                      </CardBody>
                    </Card>

                  </Col>
                  <Col lg='6' md='6' sm='12' className='mb-3'>
                    <Card>
                      <CardBody>
                        <CardImg className='ImgRadius' top width="100%" src="/assets/images/prod2/4.jpg" alt="آنتوسیانین" />
                        <CardSubtitle className="black">( آنتوسیانین )</CardSubtitle>
                      </CardBody>
                    </Card>

                  </Col>
                  <Col lg='6' md='6' sm='12' className='mb-3'>
                    <Card>
                      <CardBody>
                        <CardImg className='ImgRadius' top width="100%" src="/assets/images/prod2/5.jpg" alt="سافرانال" />

                        <CardSubtitle className="black">( سافرانال )</CardSubtitle>
                      </CardBody>
                    </Card>

                  </Col>
             
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="text-right pr-5">
            <Card>

              <CardBody>
                <CardTitle>دستاورد </CardTitle>
                <CardSubtitle>شرکت هوشمند فناوران سلامت گستر چابک</CardSubtitle>
                
                <div className='jingol'></div>
                <Row>

                  <Col lg='6' md='6' sm='12' className='mb-3'>
                    <Card>
                      <CardBody>
                        <CardImg className='ImgRadius' top width="100%" src="/assets/images/prod2/6.jpg" alt="قهوه غنی از کروسین " />
                        <CardSubtitle className="black">( قهوه غنی از کروسین )</CardSubtitle>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md='12' className='b3 mb-5 pr-5 pl-5  justify-content-center'>
                    <CardSubtitle className="text-center colorW">جهت کسب اطلاعات بیشتر در مورد هر محصول و آگاهی از ویژگی های هریک و نحوه تجاری سازی، می توانید از طریق ایمیل شرکت به نشانی info@ahealthyst.com اقدام فرمائید.
                    </CardSubtitle>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>
    </div>
  );
}

export default Products;   