import React from 'react';
import { Container, CardSubtitle, Row, Col, CardBody, CardTitle, CardImg, Card, CardText } from 'reactstrap';

function Researches(props) {
  return (
    <div>
      <img src='/assets/images/publications.jpg' alt="publications" width='100%' />
      <Container>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <CardTitle>کتاب آپوکارتنوئیدهای زعفران از سنتز تا فارماکولوژی</CardTitle>
                <CardSubtitle>شرکت هوشمند فناوران سلامت گستر چابک</CardSubtitle>
                <div className='jingol'></div>
                <Row className="justify-content-center">
                  <Col md={6}>
                    <Card>
                      {/* <CardText>کتاب آپوکارتنوئیدهای زعفران از سنتز تا فارماکولوژی</CardText> */}
                      <CardImg src='assets/images/research1.jpg' alt="پژوهش" />
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>

          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <CardTitle> مصاحبه</CardTitle>
                <CardSubtitle>شرکت هوشمند فناوران سلامت گستر چابک</CardSubtitle>
                <div className='jingol mb-5'></div>
                <Row>
                  <Col md={6} className="pr-0 pl-0">
                    <Card>
                      <CardImg src="/assets/images/mosahebe.jpg" alt="دکتر پریسا مردانی dr parisa mardani"></CardImg>
                    </Card>
                  </Col>
                  <Col md={6} className="darkBlueBox pr-0 pl-0">
                    <Card className="darkBlueBox pt-5">
                      <CardBody className="p-5">
                        <CardTitle></CardTitle>
                        <CardSubtitle>مصاحبه با روزنامه قدس و روابط عمومی سازمان تعاون روستایی در حوزه صنایع تبدیلی گیاهان دارویی :</CardSubtitle>
                        <CardText>
                          به گزارش قدس آنلاین و به نقل از روابط عمومی تعاون روستایی خراسان رضوی، خانم دکتر مردانی مدیر عامل اتحادیه گیاهان دارویی گفت: حلقه ی مفقوده  گیاهان دارویی در استان خراسان رضوی  صنایع تبدیلی است.   زیرا با ایجاد و گسترش صنایع تبدیلی  به روند استفاده بهینه از محصولات و گیاهان دارویی در این بخش کمک  فراوانی می کند.
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>

  );
}

export default Researches;   