import React from "react";
import {
  Container,
  Row,
  Col,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Card,
} from "reactstrap";

function Contract(props) {
  return (
    <div>
      <div className="bgfix1">
        <Row className="justify-content-center">
          <Col md={8} lg={8}>
            <div className="colorW">
              {/* <CardBody>
                  <CardTitle>آموزش</CardTitle>
                  <CardText>برگزاری دوره های آموزشی بصورت مجازی و حضوری در خصوص جدیدترین یافته های علمی و با دعوت از برجسته ترین اساتید مدعو در حوزه مربوطه</CardText>
                </CardBody> */}
            </div>
          </Col>
        </Row>
      </div>
      <div className="bgdark colorW">
        <CardBody>
          <Row className="justify-content-center">
            <Col md={3} lg={3}>
              <CardTitle>پروژه های تحقیقاتی</CardTitle>
            </Col>
            <Col md={6} lg={6}>
              <CardText>
                شرکت هوشمند فناوران سلامت گستر چابک در زمینه انجام پروژه های
                تحقیقاتی در داخل و خارج از کشور از طریق ارتباط با دانشگاه ها و
                ارائه طرح توجیهی محصولات جدید به کارخانجات و یا شرکت های مرتبط
                در حوزه صنایع غذایی فعالیت دارد.
              </CardText>
            </Col>
          </Row>
        </CardBody>
      </div>
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <Card>
              <CardBody>
                <CardTitle>طرح توجیهی </CardTitle>
                <CardSubtitle>شرکت هوشمند فناوران سلامت گستر چابک</CardSubtitle>
                <div className="jingol"></div>

                <CardText>
                  در تمامی حوزه ها(غذایی ، دارویی و مکمل ها و آرایشی و بهداشتی )
                  میتوان طرح توجیهی شامل ویژگی ها و مساحت مورد نیاز، لیست ماشین
                  آلات و تجهیزات مورد نیاز، منابع انسانی و مالی مورد نیاز ، مواد
                  اولیه ، بررسی بازار های هدف در داخل و خارج، بررسی رقبا و
                  محصولات مشابه ، برآورده قیمت تمام شده، سود نهایی و نرخ بازگشت
                  سرمایه را در کوتاهترین زمان ممکن آماده و ارائه نماید.
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Contract;
